import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';
import {connect} from 'react-redux';
import {UserBlob, LinkStyled} from './styles';
import {Icon} from '../../Elements/Icon/Icon';

import LogoutButton from '../Login/LogoutButton/LogoutButton';
import IconButton from '../../Elements/IconButton/IconButton';

const CurrentUser = ({user}) => (
	<UserBlob>
		{user && (
			<React.Fragment>
				<LinkStyled to="/dashboard">
					<IconButton text="Mein Dashboard">
						<Icon name={user ? 'user' : 'login'} />
					</IconButton>
				</LinkStyled>

				<LogoutButton text="Abmelden" />
			</React.Fragment>
		)}
		{!user && (
			<Link to="/login">
				<IconButton text="Anmelden">
					<Icon name="login" />
				</IconButton>
			</Link>
		)}
	</UserBlob>
);

const mapStateToProps = state => ({
	user: state.userManagement.user
});

CurrentUser.propTypes = {
	user: PropTypes.shape({
		UserId: PropTypes.string,
		UserName: PropTypes.string
	})
};

CurrentUser.defaultProps = {
	user: null
};

export default connect(
	mapStateToProps,
	null
)(CurrentUser);
